import "./App.css";
import React, { useState, useCallback, Suspense } from "react";

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

// import NewPlaylist from "./playlist/pages/NewPlaylist";
// import Venues from "./venue/pages/Venues";
// import Playlists from "./playlist/pages/Playlists";
// import Screens from "./screen/pages/Screens";
// import Auth from "./user/pages/Auth";
import MainNavigation from "./shared/components/Navigation/MainNavigation";
import { AuthContext } from "./shared/context/auth-context";
// import ScreenStream from "./screen/pages/ScreenStream";

const NewPlaylist = React.lazy(() => import('./playlist/pages/NewPlaylist'));
const Playlists = React.lazy(() => import('./playlist/pages/Playlists'));
const Screens = React.lazy(() => import('./screen/pages/Screens'));
const ScreenStream = React.lazy(() => import('./screen/pages/ScreenStream'));
const Auth = React.lazy(() => import('./user/pages/Auth'));
const Venues = React.lazy(() => import('./venue/pages/Venues'));

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const login = useCallback(() => {
    setIsLoggedIn(true);
  }, []);

  const logout = useCallback(() => {
    setIsLoggedIn(false);
  }, []);

  let routes;

  if (isLoggedIn) {
    routes = (
      <Switch>
        <Route path="/" exact>
          <MainNavigation />
          <Venues />
        </Route>
        <Route path="/:venueId/playlists" exact>
          <MainNavigation />
          <Playlists />
        </Route>
        <Route path="/:venueId/newschedule" exact>
          <MainNavigation />
          <NewPlaylist />
        </Route>
        <Route path="/:venueId/screens" exact>
          <MainNavigation />
          <Screens />
        </Route>
        <Redirect to="/" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/auth">
          <Auth />
        </Route>
        <Route path="/stream/:screenId" exact>
          <ScreenStream hideNavigation />
        </Route>
        <Redirect to="/auth" />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{ isLoggedIn: isLoggedIn, login: login, logout: logout }}
    >
      <Router>
        {/* <MainNavigation /> */}
        <main><Suspense>{routes}</Suspense></main>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
