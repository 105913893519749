import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { AuthContext } from '../../context/auth-context';
import "./NavLinks.css";

const NavLinks = (props) => {
  const auth = useContext(AuthContext);

  return (
    <ul className="nav-links">
      {auth.isLoggedIn && (
        <li>
          <NavLink to="/" exact>
            All Venues
          </NavLink>
        </li>
      )}
      {auth.isLoggedIn && (
        <li>
          <NavLink to="/settings" exact>
            Settings
          </NavLink>
        </li>
      )}
      {auth.isLoggedIn && (
        <li onClick={auth.logout}>
          <NavLink to="/auth" exact>
            Logout
          </NavLink>
        </li>
      )} 
    </ul>
  );
};

export default NavLinks;
